"use client"; // Error boundaries must be Client Components

import Image from "next/image";
import { useEffect } from "react";

import styles from "./styles.module.scss";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div className={styles.errorPageContainer}>
      <Image
        src="/images/icons/warning.svg"
        alt="Poza cu un indicator de avertizare"
        width={128}
        height={128}
      />
      <h1>A apărut o eroare!</h1>
      <p>Ne pare rău pentru inconveniența...</p>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Mergi la pagina principală
      </button>
    </div>
  );
}
