import { Metadata } from "next";
import {
  ReferrerEnum,
  Robots,
} from "next/dist/lib/metadata/types/metadata-types";
import { OpenGraph } from "next/dist/lib/metadata/types/opengraph-types";

export const API_BASE_URL =
  "https://spital-sofianu-cms-835594b8b769.herokuapp.com/api";
// export const API_BASE_URL = "http://localhost:1337/api";

export const APPOINTMENT_URL = "https://programari.balneomedcenter.ro/";
export const ANALYSIS_RESULTS_URL = "https://analize.balneomedcenter.ro/";

export const blurDataURL =
  "https://spital-sofianu.s3.eu-central-1.amazonaws.com/logo-10x10.png";

// Update image links with final domain
export const defaultMetadata: Metadata = {
  title: "BalneoMedCenter",
  description:
    "Spitalul Sofianu, spital privat din Râmnicu Vâlcea, oferă servicii medicale specializate și imagistică ultraperformantă, cu CAS și intervenții minim invazive.",

  generator: "Next.js",
  applicationName: "Spitalul Sofianu",
  referrer: "origin-when-cross-origin" as ReferrerEnum,
  keywords: [
    "Spitalul Sofianu",
    "BalneoMedCenter",
    "BalneoMedCenter Sofianu",
    "spital privat Râmnicu Vâlcea",
    "radiologie",
    "imagistică medicală",
    "RMN",
    "tomografie computerizată",
    "mamografie 3D",
    "CT 128 slice",
    "ecografie",
    "neurologie",
    "urologie",
    "geriatrie",
    "chirurgie vasculară",
    "reabilitare medicală",
    "recuperare",
    "gastroenterologie",
    "cardiologie",
    "endocrinologie",
    "dermatologie",
    "ortopedie",
    "traumatologie",
    "pneumologie",
    "obstetrică ginecologie",
    "chirurgie minim invazivă",
    "medicina muncii",
    "medicina internă",
    "decontare CAS",
    "investigații medicale",
    "servicii medicale Râmnicu Vâlcea",
    "diagnostic precis",
    "tehnologie modernă",
    "specialiști medicali",
    "servicii chirurgicale",
    "clinica medicală",
  ],

  authors: [{ name: "DECTECH", url: "https://dectech.ro" }],
  creator: "DECTECH Web Team",
  publisher: "DECTECH",

  // Open Graph Metadata
  openGraph: {
    title: "Spitalul Sofianu",
    description:
      "BalneoMedCenter, spital privat din Râmnicu Vâlcea, oferă servicii medicale specializate și imagistică ultraperformantă, cu CAS și intervenții minim invazive.",
    url: "https://spitalsofianu.ro",
    siteName: "Spitalul Sofianu",
    images: [
      {
        url: "https://spital-sofianu.dectech.ro/_next/image?url=%2Fimages%2Flogo%2Flogo-full.png&w=640&q=75",
        width: 800,
        height: 600,
        alt: "Spitalul Sofianu",
      },
    ],
    locale: "ro-RO",
    type: "website",
  },

  // Twitter Metadata
  twitter: {
    card: "summary_large_image",
    site: "@BalneoMedCenter",
    creator: "@BalneoMedCenter",
    title: "Spitalul Sofianu",
    description:
      "Spitalul Sofianu, spital privat din Râmnicu Vâlcea, oferă servicii medicale specializate și imagistică ultraperformantă, cu CAS și intervenții minim invazive.",
    images: [
      "https://spital-sofianu.dectech.ro/_next/image?url=%2Fimages%2Flogo%2Flogo-full.png&w=640&q=75",
    ],
  },

  // Icons
  icons: {
    icon: "/favicon.ico",
    apple: "/apple-touch-icon.png",
    shortcut: "/shortcut-icon.png",
  },

  // Format Detection
  formatDetection: {
    email: false,
    address: false,
    telephone: false,
  },

  // Robots and SEO configuration
  robots: {
    index: true,
    follow: true,
    nocache: true,
    googleBot: {
      index: true,
      follow: true,
      "max-snippet": -1,
      "max-image-preview": "large",
      "max-video-preview": -1,
    },
  },

  // Additional Metadata
  // alternates: {
  //   canonical: "https://balneomedcenter.ro",
  // languages: {
  //   "en-US": "https://balneomedcenter.ro/en/neurology",
  //   "ro-RO": "https://balneomedcenter.ro/ro/neurology",
  // },
  // },
};
